import { graphql } from "gatsby";
import React from "react";
import { Box, Grid } from "theme-ui";
import Layout from "../components/layout";
import ColumnContainer from "../components/layout/column-container";
import ThemeableHeader from "../components/layout/themeable-header";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import Seo from "../components/seo";

const PrivacyPolicy = ({ data }) => (
  <Layout>
    <Seo title="Privacy Policy" />
    <ThemeableHeader
      color="darkRed"
      title={"Privacy Policy"}
      active="/privacy-policy"
    />
    <Grid as="section" columns={1} gap={[4]} sx={{ my: [5, 6] }}>
      <ColumnContainer>
        <Box
          sx={{
            "> p": {
              mb: [3, 4],
            },
          }}
          dangerouslySetInnerHTML={{ __html: data.page.content }}
        />
      </ColumnContainer>
      <Box sx={{ mt: [4, 5] }}>
        <NewsletterSignup />
      </Box>
    </Grid>
  </Layout>
);

export default PrivacyPolicy;

export const query = graphql`
  {
    page: wpPage(slug: { eq: "privacy-policy" }) {
      content
    }
  }
`;
